import { Component, effect, input } from '@angular/core';

/** High Charts Related modules - BEGIN */
import { HighchartsChartModule } from 'highcharts-angular';
import HighchartsESM from 'highcharts/es-modules/masters/highcharts.src';
import 'highcharts/es-modules/masters/modules/accessibility.src';
import 'highcharts/es-modules/masters/modules/drilldown.src';
import 'highcharts/es-modules/masters/modules/exporting.src';
import 'highcharts/es-modules/masters/modules/no-data-to-display.src';
/** High Charts Related modules - END */

import { DrillDownKeys } from './models/DrillDownKeys';
import { PieChartDataBuilderModel } from '@models/PieChartDataBuilderModel';
import { GetChartColorIndex } from 'app/constants/chartColorMap';
import { BaseRecord } from '@services/pharmacy-demo/models/BaseRecord';
import { ExtendedChart } from '@models/ExtendedChart';

@Component({
  selector: 'app-drill-down-bar-chart',
  standalone: true,
  templateUrl: './drill-down-bar-chart.component.html',
  styleUrl: './drill-down-bar-chart.component.scss',
  imports: [HighchartsChartModule]
})
export class DrillDownBarChartComponent {
  rawData = input.required<BaseRecord[]>();

  colorMap = input.required<Record<string, number>>();

  /** Required by HighCharts */
  Highcharts: typeof HighchartsESM = HighchartsESM;

  private chart?: ExtendedChart;

  public setChart(chart: Highcharts.Chart) {
    this.chart = chart as ExtendedChart;
  }

  /**
   * Data series that is used by the ("Drug Status") chart
   *  Layers:
   *    Drug Status
   *    Region
   *    State
   *    Facility
   *    Location
   */
  private seriesData: Highcharts.PointOptionsObject[] = [];

  /**
   * Drill down data used by the chart
   */
  private drillDownData: Highcharts.SeriesOptionsType[] = [];

  /**
   * Series and point labels to use in the chart
   */
  private labels: Highcharts.PlotSeriesDataLabelsOptions[] = [
    {
      enabled: false,
      format: '{point.name}'
    },
    {
      enabled: true,
      distance: -50,
      filter: {
        property: 'percentage',
        operator: '>',
        value: 10
      },
      useHTML: true,
      formatter: function () {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        const pctFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        let total = 0;
        this.point.series.data.forEach((p) => (total += p.y!));
        const pct = (this.point.y! / total) * 100.0;

        return `<span class='pieLabelStyle'>${currencyFormatter.format(this.point.y!)}</span><br /><span class='pieLabelStyle'>(${pctFormatter.format(pct)}%)</span>`;
      }
    }
  ];

  /**
   * Drill down options used by both charts
   */
  private drillDownOptions: Highcharts.DrilldownOptions = {
    allowPointDrilldown: true,
    series: this.drillDownData
  };

  private groupValuePrefix = '|"';

  private groupValueSuffix = '"|';

  chartOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
      reflow: true,
      styledMode: false,
      events: {
        drilldown: function (e) {
          console.info('in drilldown', e);
          e.point.series.chart.xAxis[0].setCategories(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (e.seriesOptions as any).data.map((x: any) => x.name!),
            true
          );
        },
        drillup: function (e) {
          console.info('in drillup', e);
          e.target.xAxis[0].setCategories(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (e.seriesOptions as any).data.map((x: any) => x.name!),
            true
          );
        }
      }
    },
    credits: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false
      },
      menuItemStyle: {
        display: 'none'
      }
    },
    accessibility: {
      enabled: true,
      announceNewData: {
        enabled: true
      },
      point: {
        valuePrefix: '$'
      }
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'Extended Cost',
        align: 'middle'
      }
    },
    plotOptions: {},
    tooltip: {
      useHTML: true,
      outside: true,
      backgroundColor: '#505050', // Set a solid background color
      borderColor: '#000000', // Optional: border color
      borderWidth: 1, // Optional: border width
      style: {
        color: '#000000', // Tooltip text color
        zIndex: 1000
      },
      followPointer: true,
      formatter: function () {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        return `<span style="color: ${this.point.color}">${this.point.name}</span>: <b>${currencyFormatter.format(this.point.y!)}</b>`;
      }
    },
    series: [
      {
        name: '',
        type: 'bar',
        data: this.seriesData,
        colors: undefined
      }
    ],
    drilldown: this.drillDownOptions
  };

  /**
   * Map to quickly get the CSS class to use for a specific drug status
   */
  private drugStatusCssClassMapping = new Map<string, string>();

  constructor() {
    effect(() => {
      const data = this.rawData();
      if (data !== undefined) {
        this.generateChartData(data);
      }
    });
  }

  /**
   * Converts the data that is currently in the grid to the format that is needed by the charts that we display at the top
   * of the page.
   */
  private generateChartData(rowData: BaseRecord[]) {
    console.time('chartdata');

    // Start building the pie data structure to store the data by Status/Region/State/Facility/Location.
    const parentNode = this.createChartDataModel(null, -1, '', 'Drug Status', 'STATUS');

    // Process all of the rows to get the raw data
    rowData.forEach((x) => {
      const price = x.extendedCost;
      parentNode.totalAmount += price;

      const statusNode = this.updateAndGetChildChartDataModel(parentNode, 0, x.status, price, 'Division', 'REG')!;
      const regionNode = this.updateAndGetChildChartDataModel(statusNode, 1, x.division, price, 'Facility', 'FAC')!;
      const facilityNode = this.updateAndGetChildChartDataModel(regionNode, 2, x.facility, price, 'Drug', 'DRUG')!;

      this.updateAndGetChildChartDataModel(facilityNode, 3, x.gname, price, '', 'NA');
    });

    this.updateChart(parentNode);

    console.timeEnd('chartdata');
  }

  /**
   * Gets the CSS class name to use for a given series or data point based on its name
   * @param name The name of the series or data point that needs its cssClass
   * @returns The css class to use for the given series or data point
   */
  private getSeriesClass(name: string) {
    if (this.drugStatusCssClassMapping.has(name)) {
      return this.drugStatusCssClassMapping.get(name);
    }
    return '';
  }

  /**
   * Updates the parameters on the pie chart to allow for dynamic updates AS WELL
   * as re-rendering the chart when the chart panel is closed and re-opened.
   * @param pieParentNode The initial node that contains the series data for the chart
   */
  private updateChart(pieParentNode: PieChartDataBuilderModel) {
    const finalNodes = this.flattenEmptyNodes(pieParentNode)!;

    // Remove all of the data from the pie chart (this is ONLY for when the chart is hidden/redrawn from scratch)
    if (this.chartOptions.series?.length ?? 0 > 0) {
      this.chartOptions.series!.splice(0, this.chartOptions.series!.length);
    }
    if (this.chartOptions.drilldown!.series!.length ?? 0 > 0) {
      this.chartOptions.drilldown!.series!.splice(0, this.chartOptions.drilldown!.series!.length);
    }

    // Build the "series" data for the chart
    this.seriesData = [];
    finalNodes.children.forEach((child) => {
      const nodeId = finalNodes.childLayerPrefix + child.name + this.groupValueSuffix;
      const drilldownId = nodeId + child.childLayerPrefix;

      this.seriesData.push({
        id: nodeId,
        name: child.name,
        y: child.totalAmount,
        drilldown: drilldownId,
        className: this.getSeriesClass(child.name),
        colorIndex: GetChartColorIndex(this.colorMap(), child.name)
      });

      // Build the dill down data for this section
      this.buildDrillDownData(child, drilldownId);
    });

    // Even though we use "setData" below, we STILL have to do this here OR the chart won't render
    // correctly after we hide/show it. Why? Highcharts is weird.
    this.chartOptions.series?.push({
      name: finalNodes.chartTitle,
      type: 'bar',
      data: this.seriesData
    });

    if (this.chart) {
      this.chart!.series[0].setData(this.seriesData, true, true, false);
      this.chart.xAxis[0].setCategories(this.seriesData.map((x) => x.name!));
    }
  }

  /**
   * Flattens all empty nodes from the top down and stops once there is a node with > 1 value or we bottom out
   * @param node The starting node
   */
  private flattenEmptyNodes(node: PieChartDataBuilderModel | null): PieChartDataBuilderModel | null {
    // Only flatten this later if there is only ONE child node
    if (node && node.children.length === 1) {
      if (node.name !== '') {
        node.children[0].childLayerPrefix = `${node.childLayerPrefix}${node.name}${node.children[0].childLayerPrefix}`;
      }
      node.children[0].chartTitlePrefix = `${node.chartTitlePrefix} ${node.children[0].name} :: ${node.children[0].chartTitlePrefix}`;
      return this.flattenEmptyNodes(node.children[0]);
    }

    return node;
  }

  /**
   * Generates drill down data for the chart based on children in a parent node
   * @param parentNode The node whose child nodes need to be processed to generate the drill down data
   * @param drillDownNodeId The id to assign to the drill down data generated
   * @returns N/A. The generated data is directly added to the global drilldown object
   */
  private buildDrillDownData(parentNode: PieChartDataBuilderModel, drillDownNodeId: string) {
    if (parentNode.children.length === 0) {
      return;
    }

    const data: Highcharts.PointOptionsObject[] = [];

    parentNode.children.forEach((c) => {
      const id = drillDownNodeId + c.name + this.groupValueSuffix;
      const drillDownId = id + c.childLayerPrefix;

      const amt = c.totalAmount;
      const pct = (c.totalAmount / c.parentObject!.totalAmount) * 100.0;

      if (c.children && c.children.length > 0) {
        data.push({
          id: id,
          name: c.name,
          y: amt,
          custom: { pct: pct },
          drilldown: drillDownId,
          colorIndex: c.colorIndex
        });

        this.buildDrillDownData(c, drillDownId);
      } else {
        data.push({
          id: id,
          name: c.name,
          y: amt,
          custom: { pct: pct },
          colorIndex: c.colorIndex
        });
      }
    });

    this.chartOptions.drilldown!.series!.push({
      type: 'bar',
      id: drillDownNodeId,
      data: data
    });
  }

  /**
   * Generate a new PieChartDataBuilderModel object with default values where appropriate
   * @param depth The depth of this layer
   * @param name The friendly name to assign to this object
   * @param chartTitle The title to use on the chart if the children of this layer is used
   * @param childSuffix The suffix to use when building drill down data
   * @returns An object with the default state for a new PieChartDataBuilderModel
   */
  private createChartDataModel(
    parent: PieChartDataBuilderModel | null,
    depth: number,
    name: string,
    chartTitle: string,
    layerPrefix: DrillDownKeys
  ): PieChartDataBuilderModel {
    return {
      depth: depth,
      name: name,
      totalAmount: 0,
      children: [],
      childIndexes: new Map<string, number>(),
      parentObject: parent,
      childLayerPrefix: `[${layerPrefix}]${this.groupValuePrefix}`,
      chartTitle: chartTitle,
      chartTitlePrefix: '',
      colorIndex: GetChartColorIndex(this.colorMap(), name)
    };
  }

  /**
   * Updates a child model with the amount on a record AND return the child model back to the caller so we
   * can process children of THIS child model.
   * @param parent The parent model object in which the child object should live
   * @param depth The depth of the child object
   * @param name The name used by the child object
   * @param amount The amount to add to the child object
   * @param chartTitle The title to use on the chart if the children of this layer is used
   * @param layerPrefix The prefix to use when building drill down data
   * @returns The child object that was created/updated in the parent
   */
  private updateAndGetChildChartDataModel(
    parent: PieChartDataBuilderModel,
    depth: number,
    name: string,
    amount: number,
    chartTitle: string,
    layerPrefix: DrillDownKeys
  ) {
    let childIdx = 0;
    if (!parent.childIndexes.has(name)) {
      childIdx = parent.children.length;
      parent.childIndexes.set(name, childIdx);

      // Create a new object for this child object
      parent.children.push(this.createChartDataModel(parent, depth, name, chartTitle, layerPrefix));
    } else {
      childIdx = parent.childIndexes.get(name)!;
    }

    // Update the total amount for this child object
    parent.children[childIdx].totalAmount += amount;

    return parent.children[childIdx];
  }

  public makeFullScreen() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.chart!.fullscreen.toggle();
  }
}
